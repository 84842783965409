<template>
    <div class="menu-bar">
      <img class="logo" src="/dm-logo.webp" alt="Logo">
      <!-- <button class="send-it" @click="SendData">Send it (for testing only)</button>
      <button class="send-it" @click="GetData">Get it (for testing only)</button> -->
      
      <div class="right-section" v-if="this.renderRightSide()">
        <span class="office-name">{{ practiceName }}</span>
        <div class="vertical-bar"></div>
        <span class="setup">Plan Setup</span>
        <i class="pi pi-book grow-medium notes-icon" @click="ShowNotes()"></i>
      </div>
      <div class="modal-overlay" v-if="showNotes" @click.self="removeNotes">
        <NotesModal @click.stop @exitModal="RemoveNotes">
                
        </NotesModal>
      </div>
    </div>
  </template>
  
  <script>
  import NotesModal from './modals/NotesModal.vue';
  export default {
    components: {
      NotesModal
    },
    props: ['inputName'],
    data() {
      return {
        showNotes: false
      }
    },
    methods: {
      renderRightSide() {
        if (this.practiceName === "") {
          return false
        }
        else {
          return true
        }
      },
      ShowNotes() {
          this.showNotes = true;
      },
      RemoveNotes() {
          this.showNotes = false;
      },
    },
    computed: {
      practiceName() {
        return this.$store.state.practiceName;
      }
    }
  }
  </script>
  
  <style scoped>
  .menu-bar {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--header-height);
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    width: 100%;
    z-index: 999;
  }
  
  .logo {
    height: 50px;
    margin: 40px 0.7em;
  }

  .update-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--color-medium-gray);
    border-radius: 15px;
    padding: 0.3em;
  }

  .update-top-text {
    font-size: 0.8em;
  }

  .update-bottom-text {
    font-size: 1.5em;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .update-btn p {
    margin: 0;
    padding: 0;
    display: block;
  }

  .send-it {
    font-size: 2em;
  }

  .right-section {
  display: flex;
  align-items: center;
  margin-right: 40px;
  font-size: 1.7em;
  color: var(--color-dark-gray)
}

.office-name, .setup {
  margin-left: 10px;
  margin-right: 10px;
  /* Adjust other styles as needed */
}

.vertical-bar {
  height: 40px; /* Set the height of the vertical bar */
  width: 1px; /* Set the width of the vertical bar */
  background-color: #000; /* Color of the vertical bar */
  margin: 0 10px; /* Adjust margin as needed */
}

.notes-icon {
  font-size: 1rem;
  margin-left: 1em;
  cursor: pointer;
}
  
  /* Additional styles for other menu items if present */
  </style>
  