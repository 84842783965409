<template>
    <div id="full-container">
       <h1 class="top-text">Your Plan Overview</h1>
       <div class="window">
           
           <h2 style="margin-top: 0.5em;">Plans</h2>
           <div class="plans">
               <div class="plan" v-for="(planType, index) in store.planTypes" :key="index"  >
                   <div class="header-container">
                       <i class="pi pi-file-edit edit-icon grow-medium spacer" v-if="mode === 'view'"></i>
                       <h3>{{planType}} Plans</h3>
                       <i class="pi pi-file-edit edit-icon grow-medium" @click="Navigate(index + 2)" v-if="mode === 'view'"></i>
                   </div>
                   <div class="bottom-half" v-if="getActivePlansByType(index).length > 0">
                       <div class="plan-benefits" v-if="getActivePlansByType(index).length">
                           <h4>Benefits:</h4>
                           <p v-for="(benefit) in getPlanTypeBenefits(index)" :key="benefit.id">{{benefit.name}}</p>
                       </div>
                       <div class="plan-prices" v-if="getActivePlansByType(index).length">
                           <h4>Every:</h4>
                           <div class="plan-holder" v-for="plan in getActivePlansByType(index)" :key="plan.id">
                               <div class="two-column-row">
                                   <p class="first-col plan-title">{{ plan.frequency }} months:</p>
                                   <p class="second-col">${{plan.price}}/month</p>
                               </div>
                               <div class="two-column-row" v-if="plan.annualPriceAltered">
                                   <p class="first-col"></p>
                                   <p class="second-col" style="font-size: 1.05em;">${{plan.annualPrice}}/year</p>
                               </div>
                           </div>
                           
                       </div>
                   </div>
                   <div v-else class="none-div" style="font-size: 2em; margin-bottom: 1em;">None</div>
               </div>
           </div>
           <div class="add-on-container">
               <div class="header-container" style="margin-bottom: 0.2em;">
                   <i class="pi pi-file-edit edit-icon grow-medium spacer" v-if="mode === 'view'"></i>
                   <h2 style="margin-left: 0; margin-right: 0;">Add-Ons</h2>
                   <i class="pi pi-file-edit edit-icon grow-medium" @click="Navigate(7)" v-if="mode === 'view'"></i>
               </div>
               <div class="add-ons"  v-if="getActiveAddOns().length > 0">
                   <div class="plan" v-for="addOn in getActiveAddOns()" :key="addOn.id" style="background-color: var(--color-super-light-gray);">
                       <h3>{{addOn.title}}</h3>
                       <div class="plan-benefits">
                           <h4>Benefits:</h4>
                           <p v-for="benefit in getAddOnBenefits(addOn.id)" :key="benefit.id">{{benefit.name}}</p>
                       </div>
                       <div class="plan-prices">
                           <h4>Price:</h4>
                           <p style="color: var(--color-dark-blue)">${{addOn.price}}/month</p>
                       </div>
                   </div>
               </div>
               <div v-else class="none-div" style="font-size: 2em; margin-bottom: 1em;">None</div>
           </div>
           
           <div class="general" style="max-width: 100%; margin: 1.5em;">
               <div class="columns-row">
                   <div class="row">
                       <div class="header-container">
                           <i class="pi pi-file-edit edit-icon grow-medium spacer" v-if="mode === 'view'"></i>
                           <h3>Rewards</h3>
                           <i class="pi pi-file-edit edit-icon grow-medium" @click="Navigate(5)" v-if="mode === 'view'"></i>
                       </div>
                       <div class="two-column-row"><p class="first-col">Enrollment Fee:</p><p class="second-col">${{store.enrollmentFeeFirstVisit}}</p></div>
                   <div class="two-column-row"><p class="first-col">Rewards Points Per Visit:</p><p class="second-col">{{ store.rewardsPointsPerVisit }}</p></div>
                   <!-- <div class="two-column-row" v-if="includePoints"><p class="first-col">Reimbursement Percentage:</p><p class="second-col">{{ store.reimbursementPercentage }}%</p></div> -->
                   </div>
                   <div class="row">
                       <div class="header-container">
                           <i class="pi pi-file-edit edit-icon grow-medium spacer" v-if="mode === 'view'"></i>
                           <h3>Treatment Discounts</h3>
                           <i class="pi pi-file-edit edit-icon grow-medium" @click="Navigate(6)" v-if="mode === 'view'"></i>
                       </div>
                       <div  v-if="treatmentDiscounts.length > 0">
                           <div class="two-column-row" v-for="discount in treatmentDiscounts" :key="discount.id"><p class="first-col">{{ discount.title }}:</p><p class="second-col">{{discount.percentOff}}% off</p></div>
                       </div>
                       <div v-else class="none-div">None</div>
                   </div>
                   <div class="row" >
                       <div class="header-container">
                           <i class="pi pi-file-edit edit-icon grow-medium spacer" v-if="mode === 'view'"></i>
                           <h3>Monthly Discounts</h3>
                           <i class="pi pi-file-edit edit-icon grow-medium" @click="Navigate(6)" v-if="mode === 'view'"></i>
                       </div>
                       <div v-if="monthlyDiscounts.length > 0">
                           <div class="two-column-row" v-for="discount in monthlyDiscounts" :key="discount.id"><p class="first-col">{{ discount.title }}:</p><p class="second-col">-${{ discount.dollarsOff }}/month</p></div>
                       </div>
                       <div v-else class="none-div">None</div>
                   </div>      
               </div>
               <div class="ucr-edit-btn hover-grow" @click="Navigate(1)" v-if="mode === 'view'">
                   <i class="pi pi-file-edit edit-icon" v-if="mode === 'view'" style="position: relative"></i>
                   &nbsp; Edit UCR Fees
               </div>
           </div>

           <div class="general" v-if="mode != 'demo'">
               <div class="header-container" style="margin: 1em; margin-top: 0.25em;">
                   <i class="pi pi-file-edit edit-icon grow-medium spacer" v-if="mode === 'view'"></i>
                   <h3 style="margin: 0;">Contact Information</h3>
                   <i class="pi pi-file-edit edit-icon grow-medium" @click="Navigate(8)" v-if="mode === 'view'"></i>
               </div>
               <div class="columns-row">
                   <div class="info-column">
                       <div class="two-column-row"><p class="first-col first-col-alt">Practice Name:</p><p class="second-col second-col-alt">{{ store.practiceName }}</p></div>
                       <div class="two-column-row"><p class="first-col first-col-alt">Practice Email:</p><p class="second-col second-col-alt">{{ store.practiceEmail }}</p></div>
                       <div class="two-column-row"><p class="first-col first-col-alt">Practice Phone:</p><p class="second-col second-col-alt">{{ store.practicePhone }}</p></div>
                       <div class="two-column-row"><p class="first-col first-col-alt">Practice Website:</p><p class="second-col second-col-alt">{{ store.practiceWebsite }}</p></div>
                   </div>
                   <div class="info-column">
                       <div class="two-column-row"><p class="first-col first-col-alt">Landing Page:</p><p class="second-col second-col-alt">{{ store.landingPage }}<span v-if="store.landingPage !== ''">.menu.dental</span></p></div>
                       <div class="two-column-row"><p class="first-col first-col-alt">Practice Address:</p><p class="second-col second-col-alt">{{ store.practiceAddress }}<span v-if="store.addressLineTwo !== ''">,</span> {{ store.addressLineTwo }}<br/>{{ store.practiceCity }} {{ store.practiceState }} {{ store.practiceZip }}</p></div>
                       <div class="two-column-row"><p class="first-col first-col-alt"></p><p class="second-col second-col-alt"></p></div>
                       <div class="two-column-row"><p class="first-col first-col-alt">Brochures?</p><p class="second-col second-col-alt" v-if="includeBrochures">{{ includeBrochures }}</p> <p v-else>No</p></div>

                   </div>
               </div>
           </div>


           <div class="add-on-container" v-if="note !== ''">
               <div class="header-container">
                   <i class="pi pi-file-edit edit-icon grow-medium spacer" v-if="mode === 'view'"></i>
                   <h3>Notes</h3>
                   <i class="pi pi-file-edit edit-icon grow-medium" @click="ShowNotes" v-if="mode === 'view'"></i>
               </div>
               <div class="general" style="max-width: 40%; word-wrap: break-word;">
                   <p style="max-width: 100%;">{{ note }}</p>
               </div>
           </div>


           <div class="save-btn"
               onmouseup="this.blur();"
               @click="HandleClick()"
               v-if="(mode === 'main') && showPlanLink === false"
               style="padding-right:0.35em; padding-left: 0.35em;">{{btnText}}
           </div>
           <div class="save-btn"
               onmouseup="this.blur();"
               @click="HandleClick('confirm')"
               v-else-if="(mode === 'view' || mode === 'build') && existChanges"
               style="padding-right:0.35em; padding-left: 0.35em;">{{btnText}}
           </div>
           <div class="save-btn"
               onmouseup="this.blur();"
               @click="HandleClick()"
               v-else-if="mode === 'demo' && !showPlanLink"
               style="padding-right:0.35em; padding-left: 0.35em;">{{btnText}}
           </div>
           <div class="save-btn"
               onmouseup="this.blur();"
               @click="HandleClick('revert')"
               v-if="(mode === 'build' || mode === 'view') && existChanges"
               style="padding-right:0.35em; padding-left: 0.35em;">Discard Unsaved Changes

           </div>
           <div class="submit-success" v-if="(mode === 'main' || mode === 'build' || mode === 'demo') && showPlanLink === true">
               <p style="font-size: 1.3em; margin-bottom: 0.5em;">Configuration saved!</p>
               <div style="display: flex; flex-direction: row;">
                   <p>View & edit your plan configuration at&nbsp;</p>
                   <a :href="planLink" target="_blank" style="margin-top: 0.08em; color: var(--color-dark-blue);">{{ planLink }}</a>
               </div>
           </div>
           <div class="response-404" v-if="update404">
               We apologize, but we can't find the plan you're working on. If you have urgent changes, please contact our data team at <a href="mailto:developer@thedentalmenu.com" style="color:var(--color-bright-blue)">developer@thedentalmenu.com</a> and we'll take care of them for you!
           </div>
           <div class="response-404" v-if="received500">
               We apologize, but we encountered an error while updating your plan. If you have urgent changes, please contact our data team at <a href="mailto:developer@thedentalmenu.com" style="color:var(--color-bright-blue)">developer@thedentalmenu.com</a> and we'll take care of them for you!
           </div>
           <!-- <div class="submit-success" v-if="mode === 'demo' && showPlanLink === true">
               <p style="font-size: 1.3em; margin-bottom: 0.5em;">Configuration saved!</p>
               <div style="display: flex; flex-direction: column;">
                   <div class="link">
                       <p>If this plan is finished, send the customer to this link:&nbsp;</p>
                       <a :href="planLink" target="_blank" style="margin-top: 0.08em; color: var(--color-dark-blue);">{{ planLink }}</a>
                   </div>
                   <div class="link">
                       <p>If it is not, send them to this link:&nbsp;</p>
                       <a :href="planLink" target="_blank" style="margin-top: 0.08em; color: var(--color-dark-blue);">{{ buildLink }}</a>
                   </div>
               </div>
           </div> -->
       </div>
       <div class="modal-overlay" v-if="showNotes" @click.self="RemoveNotes">
           <NotesModal @click.stop @exitModal="RemoveNotes">
               
           </NotesModal>
       </div>
       <div class="modal-overlay" v-if="showChangelog" @click.self="RemoveChangelog">
           <Changelog :mode="changeMode" @click.stop @exitModal="RemoveChangelog" @revert="ResetChanges">

           </Changelog>
       </div>
   </div>
</template>

<script>
import { GoToComponent, SendPlanInformation, UpdatePlanDataInDB } from '../utils/functions.js'
import { mapActions } from 'vuex';
import NotesModal from './modals/NotesModal.vue'
import Changelog from './modals/Changelog.vue'
   export default {
       components: {
           NotesModal,
           Changelog
       },
       props: {
           mode: String,
       },
       data() {
           return {
               btnText: "Save Plan Information",
               planLink: "https://dentalmenuonboarding.com/view/",
               buildLink: "https://dentalmenuonboarding.com/build/",
               showPlanLink: false,
               showBuildLink: false,
               isSubmitting: false,
               showNotes: false,
               showChangelog: false,
               planKey: "",
               update404: false,
               received500: false,
               changeMode: "",
           }
       },
       methods: {
           ...mapActions(['setEverything']),
           getPlanTypeBenefits(planType) {
               return this.$store.getters.benefitsByType(planType)
           },
           getActivePlansByType(planType) {
               let result = this.$store.getters.activePlansByType(planType);
               return result
           },
           getActiveAddOns() {
               return this.$store.getters.activeAddOns();
           },
           getAddOnBenefits(id) {
               return this.$store.getters.getAddOnBenefits(id)
           },
           Navigate(num) {
               GoToComponent(this, num);
           },
           async HandleClick(changeLogMode = "") {
               if(this.mode === 'view'){
                   this.changeMode = changeLogMode;
                   this.showChangelog = true;
                   //await this.SaveChanges();
               }
               else if(this.mode === 'build') {
                   this.changeMode = changeLogMode;
                   this.showChangelog = true;
                   //await this.SaveChanges();
               }
               else if(this.mode === 'main') {
                   await this.SendData();
               }
               else if(this.mode === 'demo') {
                   await this.SendData();
                   this.buildLink += this.planKey;
                   this.showBuildLink = true;
               }
           },
           async SendData() {
               if(this.isSubmitting) {
                   return;
               }
               this.isSubmitting = true;
               this.btnText = "Saving..."
               const result = await SendPlanInformation(this.$store.state);
               if(result === 404) {
                   this.update404 = true;
                   this.btnText = "Save Changes";
                   return;
               }
               this.planLink += result;
               this.planKey = result;
               this.showPlanLink = true;
               this.isSubmitting = false;
           },
           async SaveChanges() {
               this.btnText = "Saving..."
               const result = await UpdatePlanDataInDB(this.$store.state.id, this.$store.state);
               if(result.status === 404) {
                   this.update404 = true;
                   this.btnText = "Save Changes";
                   return;
               }
               else if(result.status === 500) {
                   this.received500 = true;
                   this.btnText = "Save Changes";
                   return;
               }
               else {
                   this.planLink += result.data;
                   this.showPlanLink = true;
                   this.$emit('btnPressed');
                   this.btnText = "Changes Saved";
               }
           },
           ResetChanges() {
               console.log("Resetting changes");
               this.showPlanLink == false;
               this.setEverything(this.$store.state.uneditedState);
           },
           ShowNotes() {
               this.showNotes = true;
           },
           RemoveNotes() {
               this.showNotes = false;
           },
           ShowChangelog() {
               this.changeMode = 'confirm'
               this.showChangelog = true;
           },
           RemoveChangelog() {
               this.showChangelog = false;
           },
       },
       computed: {
           store() {
               return this.$store.state
           },
           monthlyDiscounts() {
               return this.$store.getters.getActiveMonthlyDiscounts();
           },
           treatmentDiscounts() {
               return this.$store.getters.getActiveTreatmentDiscounts();
           },
           existChanges() {
               return this.$store.state.existChanges;
           },
           includePoints() {
               return this.$store.state.includePointsInPlanPrice;
           },
           note() {
               return this.$store.state.note;
           },
           includeBrochures() {
                if(this.$store.state.includeBrochures) {
                    if(this.$store.state.brochureColors === '' || this.$store.state.brochureStyle === '') {
                        return "Yes";
                    }
                    return (this.$store.state.brochureStyle + ", " + this.$store.state.brochureColors);
                }
               else {return "No"}
           },
       },
       mounted() {
           if(this.mode === 'view') {
               this.btnText = "Save Changes"
           }
       }
   }
</script>

<style scoped>
   #full-container {
       width: 100%;
       height: 100%;
       display: flex;
       align-items: center;
       flex-direction: column;
       margin: 0;
   }

   .top-text {
       margin: 0;
       margin-top: 1%;
       margin-bottom: 1%;
       color: var(--font-color-teal)
   }

   .window {
       width: 85%;
       max-height: 100%;
       display: flex;
       flex-direction: column;
       align-items: center;
       background-color: white;
       border-radius: 20px;
       padding-bottom: 2%;
       padding-top: 2%;
   }

   .general {
       display: flex;
       flex-direction: column;
       align-items: center;
       width: 90%;
       max-width: 100%;
       padding: 1em;
       border: 3px solid var(--color-medium-gray);
       box-shadow: 3px 3px 10px var(--color-medium-gray-shadow);
       border-radius: 15px;
       background-color: var(--color-super-light-gray);
       margin-bottom: 0.5em;
   }

   .header-container {
       display: flex;
       align-items: center; /* This ensures the icon is vertically aligned with the text */
       justify-content: center; /* This centers the h3 and icon within the general div */
       width: 100%; /* Take full width to respect parent's centering */
   }

   .columns-row {
       display: flex;
       width: 100%;
       justify-content: space-between;
   }

   .info-column {
       font-size: 0.85em;
       text-align: center;
       width: 50%;
   }

   .two-column-row {
       display: flex;
       align-items: center;
       width: 100%;
   }

   .first-col {
       display: flex;
       flex: 4;
       font-size: 1em;
   }

   .first-col-alt {
       flex: 2;
       font-size: 1.1em;
       display: flex;
       justify-content: center;
       text-align: center;
       margin: 0.15em 0;
   }

   .second-col {
       display: flex;
       flex: 2;
       color: var(--color-bright-blue);
       font-size: 1.2em;
       margin-left: 0.5em;
   }

   .second-col-alt {
       flex: 5;
       display: flex;
       justify-content: center;
       text-align: center;
       font-size: 1.1em;
   }

   .ucr-edit-btn {
       display: flex;
       align-items: center;
       font-size: 1.1em;
       font-weight: 600;
       border: 3px solid var(--color-bright-blue);
       color: var(--color-dark-blue);
       padding: 0.5em 1em;
       border-radius: 100px;
       cursor: pointer;
   }

   .ucr-edit-btn:focus {
       border: 3px solid var(--color-dark-blue)
   }

   .plans {
       width: 100%;
       display: flex;
       flex-wrap: wrap;
       margin: 1em;
       justify-content: center;
   }

   .plan {
       position: relative;
       flex: 1; /* Adjust flex-grow, flex-shrink, and flex-basis */
       min-width: 20%;
       max-width: 20%;
       margin: 0.5em;
       margin-bottom: 0em;
       border: 2px solid var(--color-light-blue);
       border-radius: 15px;
       padding: 1em;
       border: 3px solid var(--color-dark-blue);
       box-shadow: 3px 3px 10px var(--color-dark-blue-shadow);
   }

   .row {
       position: relative;
       display: flex;
       flex-direction: column;
       width: 100%;
   }

   .space {
       flex: 1;
       position: relative;
       min-width: 10px;
   }

   .edit-icon, .spacer {
       color: gray;
       margin-left: 10px; /* Adjust as necessary to position the icon next to the H3 */
       font-size: 1.2em;
       cursor: pointer;
       position: relative; /* Relative to the nearest non-statically positioned ancestor */
   }

   .spacer {
       visibility: hidden; /* Makes the spacer invisible but still occupying space */
   }

   .icon-general {
       right: 15%;
       top: 11%;
   }

   .icon-plans {
       right: 10%;
       top: -1.2em;
   }

   .icon-add-on {
       right: -30%;
       top: 12%;
   }

   .icon-notes {
       right: -40%;
       top: 12%;
   }

   h2 {
       margin: 0.5em;
       font-size: 2em;
       text-align: center;
       color: var(--color-dark-blue)
   }

   h3 {
       text-align: center;
       font-size: 1.5em;
       margin-top: 0.5em;
       margin-bottom: 0.5em;
   }

   p {
       margin: 0;
       margin-top: 0.1em;
       margin-bottom: 0.1em;
   }

   .plan h4 {
       margin-top: 1em;
       border-bottom: 2px solid var(--color-medium-gray);
       margin-bottom: 5%;
   }

   .discount-container {
       display: flex;
       justify-content: center;
       width: 100%;
   }

   .discount {
       max-width: 30%;
       flex: 1;
       display: flex;
       flex-direction: column;
       border: 3px solid var(--color-medium-gray);
       border-radius: 20px;
       padding: 0.5em;
       margin: 1em;
   }

   .discount-details {
       display: flex;
       flex-direction: row;
       justify-content: center;
   }

   .discount-text {
       display: flex;
       flex-direction: column;
       padding-bottom: 1em;
       padding-top: 0;
       align-items: flex-start;
       flex: 2;
   }

   .discount p {
       width: 100%;
   }

   .discount-price {
       display: flex;
       flex-direction: column;
       align-items: flex-start;
       flex: 1;
   }

   .add-on-container {
       display: flex;
       flex-direction: column;
       align-items: center;
       width: 100%;
   }

   .h2-block {
       max-width: 15%;
       position: relative;
       display: inline-block;
   }

   .h2-special {
       display: flex;
   }

   .add-ons {
       display: flex;
       flex-wrap: wrap;
       justify-content: center;
       flex-direction: row;
       width: 100%;
   }

   .response-404 {
       width: 80%;
       text-align: center;
       color: var(--color-medium-red);
       margin-top: 1em;
   }

   .submit-success {
       margin-top: 0.5em;
       display: flex;
       flex-direction: column;
       align-items: center;
   }

   .link {
       width: 100%;
       display: flex;
       text-align: center;
       margin: 0.5em 0;
       justify-content: center;
   }

   .none-div {
       text-align: center;
       font-size: 1.5em;
       color: var(--color-bright-blue)
   }

   .hidden {
       display: none;
   }
</style>