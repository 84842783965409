<template>
    <div id="full-container">
        <h1 id="top-text-7">Add-Ons</h1>
        <div id="window-7">
            <div id="top-row">
                <h2 class="top-description">Select the add-ons you'd like to offer or create your own.</h2>
                    <!-- <button class="notes-btn grow-medium" @click="ShowNotes()">+ Add Notes</button> -->
                </div>
                <div class="add-on-container">
                    <AddOn v-for="addOn in addOns"
                    :addOn="addOn"
                    :benefits="addOn.benefits"
                    :key="addOn.id"
                    @createAddOn="createAddOn"
                    @showModal="ShowModal"
                    @edit-benefit="ShowEditBenefit"/>
                </div>
        </div>
        <div class="modal-overlay" v-if="showModal" @click.self="removeModal">
            <AddOnAddBenefitModal @click.stop @exitModal="removeModal" :addOnID="modalID">
                
            </AddOnAddBenefitModal>
        </div>
        <!-- <div class="modal-overlay" v-if="showNotes" @click.self="RemoveNotes">
            <NotesModal @click.stop @exitModal="RemoveNotes">
                
            </NotesModal>
        </div> -->
        <div class="modal-overlay" v-if="showEditBenefitModal" @click.self="RemoveEditBenefit">
            <EditBenefitDetailsModal @click.stop @exitModal="RemoveEditBenefit" :propBenefit=this.benefitToEdit :mode="'addon'">

            </EditBenefitDetailsModal>
        </div>
        <SaveChangesButton v-if="this.mode === 'view'" @btnPressed="GoToOverview()"></SaveChangesButton>
    </div>
</template>

<script>
import AddOn from './AddOn.vue';
import AddOnAddBenefitModal from './modals/AddOnAddBenefitModal.vue';
// import NotesModal from './modals/NotesModal.vue';
import EditBenefitDetailsModal from './modals/EditBenefitDetailsModal.vue';
import SaveChangesButton from './SaveChangesButton.vue';
import { GoToComponent } from '../utils/functions';
import { mapActions } from 'vuex';

export default {
    components: {
        AddOn,
        AddOnAddBenefitModal,
        SaveChangesButton,
        // NotesModal,
        EditBenefitDetailsModal
    },
    props: {
        mode: String,
    },
    data() {
        return {
            showModal: false,
            modalID: null,
            // showNotes: false,
            showEditBenefitModal: false,
        }
    },
    methods: {
        ...mapActions(['setAddOns', 'setBenefitPricesBelowUCRStore']),
        createAddOn() {
            //console.log("Entering createAddOn()")
            const maxId = this.addOns.reduce((max, addOn) => (addOn.id > max ? addOn.id : max), 0);
            
            this.addOns.push({
                id: maxId + 1,
                isActive: false,
                title: "Type to Create New Add-On",
                description: "Your description goes here.",
                price: 0,
            })
        },
        ShowModal(payload) {
            this.modalID = payload.id
            this.showModal = true;
        },
        removeModal() {
            this.showModal = false;
        },
        // ShowNotes() {
        //     this.showNotes = true;
        // },
        // RemoveNotes() {
        //     this.showNotes = false;
        // },
        GoToOverview() {
            GoToComponent(this, 9);
        },
        ShowEditBenefit(inputBenefit) {
            console.log("Show edit benefit entered in AddOns");
            this.benefitToEdit = inputBenefit;
            this.showEditBenefitModal = true;
        },
        RemoveEditBenefit() {
            this.setBenefitPricesBelowUCRStore();
            this.showEditBenefitModal = false;
        },
    },
    watch: {
        addOns: {
            handler(newVal) {
                //console.log("addOns watcher triggered")
                this.setAddOns(this.addOns)
                //console.log(this.$store.state)
            },
            deep: true
        }
    },
    computed: {
        addOns() {
            let result = this.$store.state.addOns;
            return result;
        }
    },
    
}
</script>

<style scoped>
#top-text-7 {
    margin: 0;
    margin-top: 1%;
    margin-bottom: 1%;
    color: var(--font-color-teal)
}

#full-container {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;
}

#window-7 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    padding-bottom: 2%;
}

#top-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

#top-row h2 {
    margin-left: 2em;
    margin-right: 2em;
    font-size: 1.3em;
}

#description {
    color: var(--color-dark-gray);
}

.add-on-container {
    width: 95%;
    gap: 3%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
}
</style>