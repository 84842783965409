<template>
    <div id="full-container">
        <h1 id="top-text-5">Discounts</h1>
        <div id="window-5">
            <div class="row-holder">
                <div id="top-row">
                    <h2 class="top-description" style="font-size: 1.3em;">Select the discounts you'd like to add or create your own.</h2>
                    <!-- <button class="notes-btn grow-medium" @click="ShowNotes()">+ Add Notes</button> -->
                </div>
                <div id="top-row">
                    <h3 class="description">Membership Discounts</h3>
                </div>
                <MonthlyDiscountRow
                v-for="discount in monthlyDiscounts"
                :key="discount.id"
                :id="discount.id"/>
            </div>
            <div class="row-holder">
                <div id="top-row">
                    <h3 class="description">Treatment Discounts</h3>
                </div>
                <TreatmentDiscountRow
                v-for="discount in treatmentDiscounts"
                :key="discount.id"
                :id="discount.id"/>
            </div>

            <div class="btn-container">
                <button class="add-btn hover-grow" onmouseup="this.blur();" @click = "ShowModal">+ Create New Discount</button>
            </div>
        </div>
        <SaveChangesButton v-if="this.mode === 'view'" @btnPressed="GoToOverview()"></SaveChangesButton>
        <div class="modal-overlay" v-if="showModal" @click.self="RemoveModal">
            <AddDiscountModal @click.stop @exitModal="RemoveModal" @addDiscount="AddDiscount($event)">
                
            </AddDiscountModal>
        </div>
        <!-- <div class="modal-overlay" v-if="showNotes" @click.self="RemoveNotes">
            <NotesModal @click.stop @exitModal="RemoveNotes">
                
            </NotesModal>
        </div> -->
    </div>
</template>

<script>
import MonthlyDiscountRow from './MonthlyDiscountRow.vue';
import TreatmentDiscountRow from'./TreatmentDiscountRow.vue';
import AddDiscountModal from './modals/AddDiscountModal.vue';
// import NotesModal from './modals/NotesModal.vue';
import { mapActions } from 'vuex';
//import PlusMinusButton from './PlusMinusButton.vue'
import SaveChangesButton from './SaveChangesButton.vue';
import { GoToComponent } from '../utils/functions';

export default {
    props: {
        mode: String,
    },
    components: {
        MonthlyDiscountRow,
        TreatmentDiscountRow,
        SaveChangesButton,
        AddDiscountModal,
        // NotesModal,
    },
    data() {
        return {
            showModal: false,
            // showNotes: false,
            hasLoadedInitialData: false,
        }
    },
    methods: {
        ...mapActions(['setMonthlyDiscounts', 'updateEnrollmentFee', 'setTreatmentDiscounts']),
        AddDiscount(payload) {
            //console.log("Entering Discounts.AddDiscount- payload = ", payload);
            let amount = payload.discountAmount;
            if(payload.discountType === 'monthly') {
                payload.id = this.monthlyDiscounts[this.monthlyDiscounts.length - 1].id + 1;
                delete payload.discountAmount;
                payload.dollarsOff = amount;
                delete payload.discountType;
                payload.isActive = true;
                payload.isSenior = false;
                payload.isMultiplePatient = false;
                //console.log("Discount = ", payload);
                this.monthlyDiscounts.push(payload);
            }
            else if(payload.discountType === 'treatment') {
                payload.id = this.treatmentDiscounts[this.treatmentDiscounts.length - 1].id + 1;
                delete payload.discountAmount;
                payload.percentOff = amount;
                delete payload.discountType
                payload.isActive = true;
                //("Discount = ", payload);
                this.treatmentDiscounts.push(payload);
            }
        },
        GoToOverview() {
            GoToComponent(this, 9);
        },
        ShowModal() {
            this.showModal = true;
        },
        RemoveModal() {
            this.showModal = false;
        },
        // ShowNotes() {
        //     this.showNotes = true;
        // },
        // RemoveNotes() {
        //     this.showNotes = false;
        // },
    },
    computed: {
        monthlyDiscounts() {
            let discounts = this.$store.state.monthlyDiscounts;
            return discounts;
        },
        treatmentDiscounts() {
            let discounts = this.$store.state.treatmentDiscounts;
            return discounts;
        },
    }
}
</script>

<style scoped>
#top-text-5 {
    margin: 0;
    margin-top: 1%;
    margin-bottom: 1%;
    color: var(--font-color-teal)
}

#full-container {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin:0;
}

#window-5 {
    width: 95%;
    max-height: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 20px;
}

.row-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#top-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

#top-row h2 {
    margin-left: 2em;
    font-size: 1.5em;
}

h3 {
    margin-left: 2em;
}

.description {
    color: var(--color-dark-gray);
    padding-left: 1.5%;
}

.add-btn-container {
    margin-top: 1em;
    margin-right: auto;
    margin-left: 4.Goem;
}

.add-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid var(--color-light-blue);
    border-radius: 40px;
    height: 3em;
    background-color: white;
    color: var(--color-bright-blue);
    font-size: 1.15em;
    font-weight: 600;
    padding-right: 0.7em;
    padding-left: 0.7em;
    cursor: pointer;
}

.bubble {
    background-color: white;
    color: var(--color-bright-blue);
    border: 2px solid var(--color-light-blue);
    border-radius: 10px;
    margin-left: 0.35em;
    margin-right: 0.35em;
    padding-right: 0.2em;
    font-size: 2em;
    font-weight: 900;
    min-width: 55px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-container {
    margin-top: 0.5em;
    display: flex;
    justify-content: center;
    width: 97%;
    margin-bottom: 0.5em;
}

.add-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid var(--color-light-blue);
    border-radius: 40px;
    height: 4em;
    width: 40%;
    background-color: white;
    color: var(--color-bright-blue);
    font-size: 1.5em;
    font-weight: 600;
    padding-right: 0.7em;
    padding-left: 0.7em;
    cursor: pointer;
    max-height: 55px;
    margin-top: 0;
    margin-bottom: 0;
}
</style>