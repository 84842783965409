<template>
    <div :class="sliderClass">
        <h2 class="text hover-grow" @click="emitShowEditBenefitModal()">{{ benefit.name }}</h2>
        <div class="frequency" v-if="benefit.frequencies[planType] === -1">
            <span>Each Visit</span>
        </div>
        <div class="frequency" v-else-if="benefit.frequencies[planType] === 12">
            <span>Annual</span>
        </div>
        <div class="frequency" v-else>
            Every <br/> {{ benefit.frequencies[planType] }} Months
            <!-- annually -->
             <!-- each visit -->
        </div>
        <div class="slider-container">
            <div class="tooltip no-select" :style="{ left: thumbPosition }">
                <h3 class="tooltip-text">${{ localPrice }}</h3>
                <span class="tooltip-text-lower" v-if="this.localUCR > 0">{{ percentOfUCR }}% UCR</span>
            </div>
       
            <div class="reference-price" :style="{ left: insuranceAveragePosition }">
                <h3 class="ref-price-price">${{ insuranceAveragePrice }}</h3>
                <div class="vertical-line"></div>
                <h4 class="ref-price-text">Estimated Insurance Payout</h4>
            </div>
            <!--
            <div class="reference-price" :style="{ left: cashAveragePosition }">
                <h3 class="ref-price-price">${{ cashAveragePrice }}</h3>
                <div class="vertical-line"></div>
                <h4 class="ref-price-text">Cash<br/>Average</h4>
            </div>
            <div class="reference-price min-max" :style="{ left: maxPosition }">
                <h3 class="ref-price-price no-select">${{ sliderMax }}</h3>
                <div class="vertical-line"></div>
            </div>
            <div class="reference-price min-max" :style="{ left: zeroPosition }">
                <h3 class="ref-price-price no-select">$0</h3>
                <div class="vertical-line"></div>
            </div>
            -->
            <div class="reference-price"  :style="{ left: UCRPosition }" v-if="this.localUCR > 0">
                <h3 class="ref-price-price no-select">${{ localUCR }}</h3>
                <div class="vertical-line"></div>
                <h4 class="ref-price-text no-select">UCR</h4>
            </div>
            <input
                ref="inputRange"
                type="range"
                :min="sliderMin"
                :max="sliderMax"
                value="50"
                class="slider"
                v-model="localPrice"
                @input="updatePrice($event.target.value)"
            >
        </div>
        <!-- <div class="ucr-section">
            <div class="ucr-text">Set UCR</div>
            <div>
                <span class="dollar-sign">$</span><input type="text" ref="priceInput" class="price-input grow-medium" v-model="localUCR" @input="validatePrice" />
            </div>
        </div> -->
        <div class="delete-btn">
            <i class="pi pi-trash delete grow-medium" @click="RemoveBenefit()"></i>
        </div>
        <!-- <div class="delete-btn" v-else>

        </div> -->
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { isEqual } from 'lodash';

export default {
    props: {
        benefit: Object,
        index: Number,
        planType: Number,
        inputPercent: Number,
    },
    data() {
        return {
            thumbPosition: '0%',
            insuranceAveragePosition: '0%',
            cashAveragePosition: '0%',
            UCRPosition: '0%',
            zeroPosition: '0%',
            maxPosition: '0%',
            sliderMin: 0,
            localPrice: 0,
            localUCR: 0,
            hasLoadedInitialData: false,
        }
    },
    methods: {
        ...mapActions(['updateBenefitByIDStore']),
        async updatePrice() {
            if(this.benefit.prices[this.planType] !== undefined){
                this.thumbPosition = await this.calculatePosition(this.localPrice);
                let newBenefit = JSON.parse(JSON.stringify(this.benefit));
                newBenefit.prices[this.planType] = +this.localPrice
                if(!isEqual(this.benefit, newBenefit)) {
                    this.updateBenefitByIDStore(newBenefit)
                this.$emit('benefitPriceUpdated');
                }
            }
        },
        calculatePosition(price) {
            return new Promise((resolve) => {
                this.$nextTick(() => {
                    const thumbWidth = 38;
                    const fraction = (price - this.sliderMin) / (this.sliderMax - this.sliderMin);
                    const result = `calc(${fraction * 100}% + ${(0.5 - fraction) * thumbWidth - (price / 50)}px)`;
                    resolve(result);
                });
            });
        },
        async calculateAveragePricePositions() {
            //this.cashAveragePosition = await this.calculatePosition(this.cashAveragePrice)
            this.insuranceAveragePosition = await this.calculatePosition(this.insuranceAveragePrice);
            this.UCRPosition = await this.calculatePosition(this.localUCR);
            this.zeroPosition = await this.calculatePosition(0);
            this.maxPosition = await this.calculatePosition(this.sliderMax);
        },
        calculatePositions() {
            this.updatePrice()
            this.calculateAveragePricePositions()
        },
        RemoveBenefit() {
            //("Entering RemoveBenefit")
            let newBenefit = this.benefit;
            newBenefit.planTypes = this.benefit.planTypes.filter(planType => planType !== this.planType);
            delete newBenefit.frequencies[this.planType];
            delete newBenefit.prices[this.planType];
            this.$store.dispatch('updateBenefitByIDStore', newBenefit).then(() => {
                //console.log("Emitting benefitPriceUpdated")
                this.$emit('benefitPriceUpdated');
            });
        },
        emitShowEditBenefitModal() {
            this.$emit('edit-benefit', this.benefit);
        }
    },
    mounted() {
        this.localPrice = this.benefit.prices[this.planType];
        this.localUCR = this.benefit.UCR
        this.calculatePositions();
        this.hasLoadedInitialData = true;
    },
    computed: {
        sliderClass() {
            return {
                'slider-row': true,
                'bg-gray-global': this.index % 2 === 0
            }
        },
        percentOfUCR() {
            return Math.round((this.benefit.prices[this.planType] / this.localUCR) * 100)
        },
        sliderMax() {
            //return Math.max((Math.ceil(this.localUCR / 50) * 50), 100)
            return this.localUCR;
        },
        showDelete() {
            const array = ["D0120", "D0274", "D1110", "D1120", "D4910"]
            return !array.includes(this.benefit.code);
        },
        insuranceAveragePrice() {
            return Math.round(this.localUCR * 0.55)
        }
    },
    watch: {
        inputPercent: {
            immediate: true,
            handler(newValue) {
                // Set localPrice to inputPercent * localUCR, rounded to the nearest integer
                this.localPrice = Math.round((newValue / 100) * this.localUCR);
                this.updatePrice()
            }
        },
        localUCR: {
            immediate: true,
            handler(newValue, oldValue) {
                return 3;
            },
        },
        'benefit.UCR': {
            immediate: true,
            handler(newVal) {
                this.localUCR = newVal;
                this.calculatePositions();
            }
        },
        'benefit.prices': {
        deep: true,
        handler(newVal) {
            // Assuming there's always a valid price at the specified planType index
            // This checks if there's a change and updates localPrice accordingly
            if (newVal[this.planType] !== undefined && this.localPrice !== newVal[this.planType]) {
                this.localPrice = newVal[this.planType];
                this.thumbPosition = this.updatePrice();
            }
        }
    },
    },
}
</script>

<style scoped>
.slider-row {
    width: 97%;
    height: 90px;
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
}

.slider-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;
}



.text {
    width: 22%;
    text-align: center;
    margin-right: 2em;
    padding-left: 1em;
    margin-left: 0;
    font-size: 1.5em;;
    padding-bottom: 8px;
    padding-top: 8px;
    cursor: pointer;
}

.slider {
    width: 100%;
    appearance: none;
    background-color: transparent;
    margin-top: 2em;
}


.slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    background: black;
    border: none;
    border-radius: 1px;
    z-index: 5;
}

.slider::-moz-range-track {
    width: 100%;
    height: 1px;
    background: black;
    border: none;
    border-radius: 1px;
    z-index: 5;
}

/* WebKit (Chrome, Safari) */
.slider::-webkit-slider-thumb {
    width: var(--slider-thumb-size);
    height: var(--slider-thumb-size);
    background-image: url('../../../../public/slider-thumb.png');
    border: none;
    background-size: cover;
    background-repeat: no-repeat;
    appearance: none;
    margin-top: -16px;
    cursor: pointer;
    z-index: 5;
}

/* Mozilla (Firefox) */
.slider::-moz-range-thumb {
    width: var(--slider-thumb-size);
    height: var(--slider-thumb-size);
    background-image: url('../../../../public/slider-thumb.png');
    border: none;
    background-size: cover;
    background-repeat: no-repeat;
    appearance: none;
    margin-top: -15px;
    cursor: pointer;
    z-index: 5;
}

.tooltip {
    position: absolute;
    bottom: 90%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    
    background-color: #fff;
    border: 2px solid var(--color-light-blue);
    border-radius: 16px;
    padding: 4px 16px;
    color: var(--color-bright-blue);
    height: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 3px 5px var(--color-bright-blue-shadow);
    z-index: 5;
}

.tooltip-bottom {
    bottom: -1000%;
}

.tooltip-text {
    font-size: 1.5em;
    margin: 0;
    margin-top: 3px;
    color: var(--color-dark-blue)
}

.tooltip-text-lower {
    font-size: 0.9em;
    margin: 0;
    margin-bottom: 5px;
    color: var(--color-dark-blue)
}

.tooltip-text-bottom {
    margin: 0;
}

.reference-price {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 1;
}

.ref-price-text {
    margin-top: 0.8em;
    font-size: 0.8em;
}

.ref-price-price {
    margin-bottom: 0;
    color: var(--color-medium-gray);
}

.min-max {
    bottom: 2px;
}

.ucr {
    bottom: -45px;
}

.vertical-line {
    width: 1px;
    background-color: black;
    height: 1em;
}

.delete-btn {
    margin: 2em;
    width: 3%;
    z-index: 999;
}

.frequency {
    color: var(--color-medium-gray);
    font-size: 1.1em;
    margin-right: 1.5em;
    text-align: center;
}

/* .ucr-section {
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-bottom: 15px;
}

.ucr-text {
    color: var(--color-dark-blue);
    font-weight: bold;
}

.dollar-sign {
    color: black;
    font-weight: bold;
    font-size: 1.2em;
} */

</style>