<template>
    <div class="full-container">
        <h1 class="top-text">Website & Brochure Details</h1>
        <div class="window">
            <div class="top-row">
                <h2 class="top-description">Set up your contact information & promotional materials.</h2>
                <!-- <button class="notes-btn grow-medium" @click="ShowNotes()">+ Add Notes</button> -->
            </div>
            <div class="error-message" v-if="userTriedToAdvance && !allFilled && mode === 'intake'">
                <h4 style="margin:0; margin-bottom: 1em; color: var(--color-medium-red)">Please fill out all fields to continue</h4>
            </div>
            <div class="error-message" v-if="userTriedToAdvance && practiceName === '' && mode !== 'intake'">
                <h4 style="margin:0; margin-bottom: 1em; color: var(--color-medium-red)">Please input your practice's name</h4>
            </div>
            <div class="contact-row">
                <h3 class="contact-text">Practice Name:</h3>
                <div class="input-container">
                    <input type="text"
                    class="contact-input hover-grow"
                    :class="{'input-incorrect': (practiceName == '' && userTriedToAdvance)}"
                    v-model="practiceName">
                </div>
            </div>
            <div class="contact-row">
                <h3 class="contact-text">Practice Email:</h3>
                <div class="input-container">
                    <input type="text"
                    class="contact-input hover-grow"
                    :class="{'input-incorrect': (practiceEmail == '' && userTriedToAdvance)}"
                    v-model="practiceEmail">
                </div>
            </div>
            <div class="contact-row">
                <h3 class="contact-text">Practice Website:</h3>
                <div class="input-container hover-grow">
                    <p class="after-input-text">https://www.</p>
                    <input type="text"
                    class="contact-input"
                    :class="{'input-incorrect': (practiceWebsite == '' && userTriedToAdvance)}"
                    v-model="practiceWebsite">
                </div>
            </div>
            <div class="contact-row" v-if="this.mode !== 'intake'">
                <h3 class="contact-text">Dental Menu Landing Page:</h3>
                <div style="flex: 1;">
                    <div class="input-container hover-grow">
                        <input type="text"
                        class="contact-input"
                        :class="{'input-incorrect': (landingPage == '' || !isAvailable ) && userTriedToAdvance}"
                        style="text-align: right;"
                        v-model="landingPage"
                        @input="debouncedCheckValue">
                        <p class="after-input-text">.menu.dental</p>
                        <div class="lds-dual-ring" v-if="isChecking && landingPage !== ''"></div>
                        <div class="is-available-checkmark" v-if="isAvailable && landingPage !== ''">✔</div>
                    </div>
                    <p class="landing-page-error" v-if="!isAvailable && !isChecking && landingPage.length">Landing page not available- choose another</p>
                    
                </div>
                
            </div>
            <div class="contact-row">
                <h3 class="contact-text">Practice Address:</h3>
                <div class="input-container">
                    <input type="text"
                    class="contact-input hover-grow"
                    :class="{'input-incorrect': (practiceAddress == '' && userTriedToAdvance)}"
                    v-model="practiceAddress">
                </div>
            </div>
            <div class="contact-row" style="margin-bottom: 0.5em;">
                <h3 class="contact-text"></h3>
                <div class="input-container">
                    <input type="text"
                    class="contact-input hover-grow"
                    v-model="addressLineTwo">
                </div>
            </div>
            <div class="contact-row" style="margin-bottom: 0.5em;">
                <h3 class="contact-text"></h3>
                <div class="input-container">
                    <p class="before-input-text">City:</p>
                    <input type="text"
                    class="contact-input hover-grow"
                    :class="{'input-incorrect': (practiceCity == '' && userTriedToAdvance)}"
                    v-model="practiceCity">
                </div>
            </div>
            <div class="contact-row">
                <h3 class="contact-text"></h3>
                <div class="input-container">
                    <p class="before-input-text">State:</p>
                    <select v-model="practiceState" class="hover-grow" style="width: 70.5%;" :class="{'input-incorrect': (practiceState == '' && userTriedToAdvance)}">
                        <!-- Placeholder option -->
                        <option disabled value="">Select</option>
                        <option v-for="(state, index) in states" :key="index" :value="state">{{ state }}</option>
                    </select>
                    <p class="before-input-text" style="margin-left: 5%;">Zip:</p>
                    <input type="text"
                    class="contact-input hover-grow"
                    :class="{'input-incorrect': ((practiceZip == '' || practiceZip.length <= 4) && userTriedToAdvance)}"
                    v-model="practiceZip"
                    @input="validateZip">
                </div>
            </div>
            <div class="contact-row">
                <h3 class="contact-text">Phone Number:</h3>
                <div class="input-container">
                    <input type="text"
                    class="contact-input hover-grow"
                    :class="{'input-incorrect': (practicePhone == '' && userTriedToAdvance)}"
                    v-model="practicePhone" @input="validatePhone($event.target.value)">
                </div>
            </div>
            <div class="contact-row">
                <h3 class="contact-text">Include Marketing Brochures?</h3>
                <div class="checkbox-container" style="position:relative;">
                    <div class="checkbox grow-medium" @click="toggleChecked()">
                        <div class="checkbox-fill" v-if="this.includeBrochures">
                            <p class="checkbox-text no-select">✓</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="includeBrochures" style="font-weight: 600;">See brochure designs at <a href="https://www.dentalmenu.com/brochures" target="_blank" style="color: var(--color-bright-blue)">https://www.dentalmenu.com/brochures</a></div>
            <div class="contact-row" v-if="includeBrochures">
                <h3 class="contact-text no-select">Brochure Style:</h3>
                <select v-model="brochureStyle" class="hover-grow no-select" :class="{'input-incorrect': (includeBrochures && brochureStyle === '' && userTriedToAdvance)}">
                    <!-- Placeholder option -->
                    <option disabled value="">Select an option</option>
                    <option :value="'Family Photography'">Family Photography</option>
                    <option :value="'Decorative Swirls'">Decorative Swirls</option>
                    <option :value="'Modern Blocks'">Modern Blocks</option>
                    <option :value="'Illustrated'">Illustrated</option>
                </select>
            </div>
            <div class="contact-row" v-if="includeBrochures">
                <h3 class="contact-text no-select">Brochure Colors:</h3>
                <select v-model="brochureColors" class="hover-grow no-select" :class="{'input-incorrect': (includeBrochures && brochureColors === '' && userTriedToAdvance)}">
                    <!-- Placeholder option -->
                    <option disabled value="">Select an option</option>
                    <option :value="'Blues'">Blues</option>
                    <option :value="'Summer'">Summer</option>
                    <option :value="'Tahiti'">Tahiti</option>
                    <option :value="'Earth'">Earth Tones</option>
                    <option :value="'Royal'">Royal</option>
                </select>
            </div>
        <button class="submit-button grow-medium" @click="onSubmitClick" v-if="this.mode === 'intake'">Let's Go</button>
        </div>
        <SaveChangesButton v-if="this.mode === 'view'" @btnPressed="GoToOverview()"></SaveChangesButton>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import SaveChangesButton from './SaveChangesButton.vue';
import { InputToInteger, GoToComponent, IsLandingPageAvailable } from '../utils/functions';
import debounce from 'lodash/debounce';
export default {
    components: {
        SaveChangesButton,
    },
    props: {
        mode: String,
        userTriedToAdvance: Boolean,
    },
    data() {
        return {
            brochuresChecked: false,
            debouncedCheckValue: null,
            isAvailable: true,
            isChecking: false,
        }
    },
    methods: {
        ...mapActions([
            'updatePracticeName',
            'updatePracticeEmail',
            'setPracticeWebsite',
            'setLandingPage',
            'setPracticeAddress',
            'setAddressLineTwo',
            'setPhoneNumber',
            'setIncludeBrochures',
            'setBrochureStyle',
            'setBrochureColor',
            'setPracticeCity',
            'setPracticeState',
            'updatePracticeZip']),
        toggleChecked() {
            this.setIncludeBrochures();
        },
        validateZip() {
            this.practiceZip = this.practiceZip.replace(/\D/g, '');
            if (this.practiceZip.length > 5) {
                this.practiceZip = this.practiceZip.slice(0, 5);
            }
        },
        validatePhone(val) {
            this.practicePhone = this.practicePhone.replace(/\D/g, '');
            if (this.practicePhone.length > 10) {
                this.practicePhone = this.practicePhone.slice(0, 10);
            }
            this.practicePhone = InputToInteger(this.practicePhone);
        },
        GoToOverview() {
            GoToComponent(this, 9);
        },
        onSubmitClick() {
            //Verify valid input in practiceName, practiceZip
            this.$emit('emitSwap', { shouldGoForward: true });
        },
        async checkLandingPageURL() {
            if(this.mode !== 'intake') {
                this.isAvailable = false;
                this.isChecking = true;
                this.$emit('landingPageChecking');
                this.$emit('landingPageAvailable', {available: this.isAvailable});
                if (this.landingPage.length === 0) {
                    this.$emit('landingPageChecking')
                    return;
                }
                try {
                    this.isAvailable = await IsLandingPageAvailable(this.landingPage);
                } catch (error) {
                    this.isAvailable = false;
                }
                this.$emit('landingPageChecking')
                this.isChecking = false;
                this.$emit('landingPageAvailable', {available: this.isAvailable});
            }
            
        },
    },
    computed: {
        practiceName: {
            get() {
                return this.$store.state.practiceName;
            },
            set(val) {
                this.updatePracticeName(val)
            }
        },
        practiceEmail: {
            get() {
                return this.$store.state.practiceEmail;
            },
            set(val) {
                this.updatePracticeEmail(val)
            }
        },
        practiceWebsite: {
            get() {
                return this.$store.state.practiceWebsite;
            },
            set(val) {
                this.setPracticeWebsite(val);
            }
        },
        landingPage: {
            get() {
                return this.$store.state.landingPage;
            },
            set(val) {
                this.setLandingPage(val);
            }
        },
        practiceAddress: {
            get() {
                return this.$store.state.practiceAddress;
            },
            set(val) {
                this.setPracticeAddress(val);
            }
        },
        addressLineTwo: {
            get() {
                return this.$store.state.addressLineTwo;
            },
            set(val) {
                this.setAddressLineTwo(val);
            }
        },
        practicePhone: {
            get() {
                return this.$store.state.practicePhone;
            },
            set(val) {
                this.setPhoneNumber(val);
            }
        },
        includeBrochures() {
            return this.$store.state.includeBrochures;
        },
        brochureStyle: {
            get() {
                return this.$store.state.brochureStyle;
            },
            set(val) {
                this.setBrochureStyle(val);
            }
        },
        brochureColors: {
            get() {
                return this.$store.state.brochureColors;
            },
            set(val) {
                this.setBrochureColor(val);
            }
        },
        practiceCity: {
            get() {
                return this.$store.state.practiceCity;
            },
            set(val) {
                this.setPracticeCity(val);
            }
        },
        practiceState: {
            get() {
                return this.$store.state.practiceState;
            },
            set(val) {
                this.setPracticeState(val);
            }
        },
        practiceZip: {
            get() {
                return this.$store.state.practiceZip;
            },
            set(val) {
                this.updatePracticeZip(val);
            }
        },
        states() {
            return this.$store.state.allStates;
        },
        allFilled() {
            return this.$store.getters.contactPageFilled(this.mode);
        }
    },
    created() {
        this.debouncedCheckValue = debounce(this.checkLandingPageURL, 500);
        this.checkLandingPageURL();
    }
}
</script>

<style scoped>

.full-container {
    width: 100%;
    height: 150%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin:0;
}

.top-text {
    margin: 0;
    margin-top: 1%;
    margin-bottom: 1%;
    color: var(--font-color-teal);
}

.window {
    width: 75%;
    height: auto;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 1em;

}

.top-row {
    width: 95%;
    display: flex;
    justify-content: space-between;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 0.85em;
}

.contact-row {
    display: flex;
    width: 70%;
    align-items: center;
}

h3 {
    margin: 0.75em 0;
}

.contact-text {
    flex: 1;
}

.input-container {
    display: flex;
    flex: 1;
    align-items: flex-end;
    width: 100%;
}

.contact-input {
    width: 100%;
    height: 25px;
    border: 2px solid var(--color-medium-light-gray);
    border-radius: 5px;
    font-weight: 600;
}

.before-input-text {
    margin: 0;
    margin-bottom: 5px;
    margin-right: 3%;
}

.after-input-text {
    margin: 0;
    margin-bottom: 5px;
    color: var(--color-dark-blue);
    font-weight: 900;
}

.contact-input:hover {
    border: 2px solid var(--color-light-blue);
}

.contact-input:focus {
    border: 2px solid var(--color-bright-blue);
    outline: none;
}

.checkbox-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 29px;
    height: 29px;
    border: 2px solid var(--color-dark-blue);
    border-radius: 5px;
    cursor: pointer;
}

.checkbox-fill {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 27px;
    background-color: var(--color-dark-blue);
    border-radius: 3px;
    color: white;
}

.checkbox-text {
    margin: 0;
    font-size: 1.5em;
}

select {
    background-color: white;
    width: 50.5%;
    border: 2px solid var(--color-medium-light-gray);
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
}

.submit-button {
    margin-bottom: 1em;
    font-size: 1.25em;
}

select:hover {
    border: 2px solid var(--color-light-blue);
}

.landing-page-error {
    display: flex;
    justify-content: center;
    margin: 0;
    color: var(--color-medium-red);
    font-size: 0.85em;
}

.is-available-checkmark {
    color: green;
    margin: 5px;
    margin-top: 0px;
    font-size: 1.25em;
}

.lds-dual-ring {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 15px;
  margin-left: 8px;
  margin-top: 0px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 2000%;
  border: 3px solid transparent;
  border-color: var(--color-dark-blue) transparent var(--color-dark-blue) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>